<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <!-- <upgrade-to-pro></upgrade-to-pro> -->
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import UpgradeToPro from './components/UpgradeToPro.vue'

export default {
  metaInfo: {
    title: 'VDC Platform | Digital Marketing Platform - Influencer Marketing',
    titleTemplate: '%s | VDC Platform',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'UTF-8' },
      { name: 'description', content: 'Digital Marketing Agency Solutions For Your Business. VDC help your brand grow with digital marketing.'},
      { name: 'keywords', content: 'vdc management, digital marketing agency, marketing agency, influencer marketing, management, influencer management'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    LayoutBlank,
    LayoutContent,
    UpgradeToPro,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>

<style lang="scss">
  @import './assets/scss/custom.scss';
</style>
