import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import MultiFiltersPlugin from './plugins/multifilters/MultiFilters'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueCryptojs from 'vue-cryptojs'
import axios from 'axios'
import VueMeta from 'vue-meta'
import loader from "vue-ui-preloader";

Vue.use(loader);
Vue.use(VueMeta, {
  keyName: 'head'
})
Vue.use(VueCryptojs)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

Vue.use(MultiFiltersPlugin);

axios.defaults.showLoader = true;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
