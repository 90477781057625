<template>
  <div >
    <!-- KETIKA LOGIN -->
    <v-menu
      v-if="isLogin"
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }" >
        <!-- <i v-bind="attrs"
            v-on="on" class="far fa-user-circle mt-1" style="font-size:28px"></i> -->

          <v-avatar
            size="40px"
            v-bind="attrs"
            v-on="on"
            aria-label="account"
          >
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        <!-- <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            v-bind="attrs"
            v-on="on"
          >
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        
        </v-badge> -->
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar size="40px">
              <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="text--primary font-weight-semibold mb-n1 text-capitalize">
              {{ business_name }}
            </span>
            <small class="text--disabled text-capitalize">{{ pic_name }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Profile -->
        <v-list-item link @click="goToProfile">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item link @click="goToPayment">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Daftar Pembayaran</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- Email -->
        <!-- <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiEmailOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inbox</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- Chat -->
        <!-- <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiChatOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Chat</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-badge
              inline
              color="error"
              content="2"
            >
            </v-badge>
          </v-list-item-action>
        </v-list-item> -->

        <!-- <v-divider class="my-2"></v-divider> -->

        <!-- Settings -->
        <!-- <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- Pricing -->
        <!-- <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pricing</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- FAQ -->
        <!-- <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22" color="error">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="error--text font-weight-medium">Keluar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    
    <!-- KETIKA BELUM LOGIN -->
    <v-menu
      v-else
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <div fab @click="goToLogin()" class="pt-1 white--text" v-bind="attrs" aria-label="account"
          v-on="on">
          <i v-if="isMobileWidth === 1" class="text-30 fas fa-user-circle accent--text"></i>
          <i v-else class="text-24 fas fa-user-circle white--text"></i>
          <!-- <span v-else>Akun</span> -->
        </div>
      </template>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiCashMultiple,
} from '@mdi/js'

export default {
  props:['business_name', 'pic_name'],
  setup() {
    return {
      isLogin:false,
      isMobileWidth:0,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiCashMultiple
      },
    }
  },
  mounted(){
    if(!localStorage.getItem('token')){
      this.isLogin = false
    } else {
      this.isLogin=true
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    // cekProfile(){
    //   let getUser = JSON.parse(localStorage.getItem('user'));
    //   this.business_name = getUser.business_name;
    //   this.pic_name = getUser.pic_name;
    // },
    goToLogin(){
        this.$router.push({name:'login'}); 
    },
    goToProfile(){
      if(this.$route.name != 'profile'){
        this.$router.push({name:'profile'}); 
      } 
    },
    goToPayment(){
      if(this.$route.name != 'campaign-payment-list'){
        this.$router.push({name:'campaign-payment-list'}); 
      } 
    },
    logout(){
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$router.push({name:'login'});
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
