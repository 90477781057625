import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const Home = () => import('@/views/home/Home.vue')
  // import(/* webpackChunkName: "group-user" */ './UserDetails.vue')

const routes = [
  // {
  //   path: '/',
  //   redirect: 'dashboard',
  // },
  // {
  //   path: '/',
  //   redirect: 'login',
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import('@/views/dashboard/Dashboard.vue'),
  // },
  // {
  //   path: '/master/quotation',
  //   name: 'master-quotation',
  //   component: () => import('@/views/master/MasterQuotation.vue'),
  // },
  // {
  //   path: '/quotation',
  //   name: 'quotation',
  //   component: () => import('@/views/quotation/Quotation.vue'),
  // },
  // {
  //   path: '/quotation/detail',
  //   name: 'quotation-detail',
  //   component: () => import('@/views/quotation/QuotationDetail.vue'),
  //   props: route => ({ query: route.query.q })
  // },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import('@/views/campaign/Campaign.vue'),
  },
  {
    path: '/campaign/detail',
    name: 'campaign-detail',
    component: () => import('@/views/campaign/CampaignDetail.vue'),
  },
  {
    path: '/campaign/report',
    name: 'campaign-report',
    component: () => import('@/views/campaign/CampaignReport.vue'),
  },
  // {
  //   path: '/campaign/choose-influencer',
  //   name: 'campaign-choose-influencer',
  //   component: () => import('@/views/campaign/ChooseInfluencer.vue'),
  //   props: route => ({ query: route.query.q })
  // },
  // {
  //   path: '/campaign/list-package',
  //   name: 'campaign-list-package',
  //   component: () => import('@/views/campaign/ListPackage.vue'),
  //   props: route => ({ query: route.query.q })
  // },
  // {
  //   path: '/campaign/cart',
  //   name: 'campaign-cart',
  //   component: () => import('@/views/campaign/Cart.vue'),
  // },
  // {
  //   path: '/campaign/payment',
  //   name: 'campaign-payment',
  //   component: () => import('@/views/campaign/Payment.vue'),
  // },
  // {
  //   path: '/campaign/payment/payment-detail',
  //   name: 'campaign-payment-detail',
  //   component: () => import('@/views/campaign/PaymentDetail.vue'),
  // },
  // {
  //   path: '/campaign/payment/payment-list',
  //   name: 'campaign-payment-list',
  //   component: () => import('@/views/campaign/PaymentList.vue'),
  // },
  // {
  //   path: '/report',
  //   name: 'report',
  //   component: () => import('@/views/report/Report.vue'),
  // },
  // {
  //   path: '/report/detail',
  //   name: 'report-detail',
  //   component: () => import('@/views/report/ReportDetail.vue'),
  // },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
  },
  {
    path: '/category-all',
    name: 'category-all',
    component: () => import('@/views/category/CategoryAll.vue'),
  },
  {
    path: '/category/detail',
    name: 'category-detail',
    component: () => import('@/views/category/Category.vue'),
  },
  {
    path: '/nano-influencer',
    name: 'nano-influencer',
    component: () => import('@/views/influencer/Nano.vue'),
  },
  {
    path: '/micro-influencer',
    name: 'micro-influencer',
    component: () => import('@/views/influencer/Nano.vue'),
  },
  {
    path: '/premium-influencer',
    name: 'premium-influencer',
    component: () => import('@/views/influencer/Nano.vue'),
  },
  {
    path: '/tiktok-influencer',
    name: 'tiktok-influencer',
    component: () => import('@/views/influencer/Nano.vue'),
  },
  {
    path: '/rekomendasi-influencer',
    name: 'rekomendasi-influencer',
    component: () => import('@/views/influencer/Nano.vue'),
  },
  {
    path: '/search-influencer',
    name: 'search-influencer',
    component: () => import('@/views/influencer/Nano.vue'),
  },
  {
    path: '/influencer/detail',
    name: 'influencer-detail',
    component: () => import('@/views/influencer/InfluencerDetail.vue'),
  },
  {
    path: '/package-all',
    name: 'package-all',
    component: () => import('@/views/package/Package.vue'),
  },
  {
    path: '/package/detail',
    name: 'package-detail',
    component: () => import('@/views/package/PackageDetail.vue'),
  },
  {
    path: '/join-talent',
    name: 'join-talent',
    component: () => import('@/views/redirect/Redirect.vue'),
  },
  // {
  //   path: '/term-conditions',
  //   name: 'term-conditions',
  //   component: () => import('@/views/redirect/Redirect.vue'),
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/redirect/Redirect.vue'),
  },
  {
    path: '/term-conditions',
    name: 'term&conditions',
    component: () => import('@/views/pages/Term.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/Faq.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/pages/Privacy.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
