<template>
  <div>
    <!-- MOBILE -->
    <v-app v-if="isMobileWidth === 1">
      <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>
      <v-app-bar
        app
        flat
        absolute
        color="white"
        elevate-on-scroll
        class="fixed"
        style="z-index: 1000; left:0px !important"
      >
        <div class="boxed-container w-full" style="max-width:500px !important;">
          <!-- HALAMAN HOME -->
          <div v-if="page === '/'" class="d-flex justify-space-between mx-7">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              style="display: block !important;justify-content:start !important"
              class="d-block d-lg-none mr-0 pr-0 accent--text"
              @click="isDrawerOpen = !isDrawerOpen"
            ><i class="fas fa-bars" style="font-size:28px"></i></v-app-bar-nav-icon>

            <v-spacer></v-spacer>
            <router-link
              to="/"
              class="d-flex align-center text-decoration-none"
            >
              <v-img
                :src="require('@/assets/images/logos/logo-primary.png')"
                width="70px"
                alt="logo"
                contain
                eager
                class="app-logo me-3"

              ></v-img>
              <v-slide-x-transition>
                <!-- <h2 class="app-title text--primary">
                  VDC Platform
                </h2> -->
              </v-slide-x-transition>
            </router-link>
            <v-spacer></v-spacer>

            <!-- Right Content -->

            <!-- PROFILE -->
            <app-bar-user-menu :business_name="business_name" :pic_name="pic_name"></app-bar-user-menu>
          </div>

          <!-- HALAMAN DETAIL -->
          <div v-else class="d-flex justify-space-between mx-7 pt-2">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              style="display: block !important;justify-content:start !important"
              class="d-block d-lg-none mr-0 pr-0 accent--text"
              @click="$router.go(-1)"
            ><i class="fas fa-chevron-left text-24"></i></v-app-bar-nav-icon>

            <v-spacer></v-spacer>
            <router-link
              to="/"
              class="d-flex align-center text-decoration-none"
            >
              <p class="text-capitalize black--text body-1 pt-2 font-weight-bold"> {{ pageItem | title}}</p>
              <v-slide-x-transition>
              </v-slide-x-transition>
            </router-link>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon
              style="display: block !important;justify-content:end !important"
              class="d-block d-lg-none ml-0 pl-0 white--text"
              @click="$router.go(-1)"
            ><i class="fas fa-chevron-left text-24"></i></v-app-bar-nav-icon>
          </div>
        </div>
      </v-app-bar>
      <v-main style="padding-left: 0px !important;">
        <div class="app-content-container boxed-container white" style="max-width:500px;min-height:100%">
          <slot></slot>
        </div>
      </v-main>
      <v-bottom-navigation
        v-if="pageItem != 'campaign-detail'"
        color="white"
        fixed
        class="d-flex"
        horizontal
        style="bottom:-1px;box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important"
      >
        <div class="boxed-container w-full" style="max-width:500px !important;">
          <v-row no-gutters>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize"
                height="60px"
                tile
                @click="goToHome()"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'home' || menu === ''" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiHome }}
                    </v-icon><br>
                    <p class="text-12  primary--text">Beranda</p>
                  </div>
                  <div v-else>
                    <v-icon class="mb-0 mt-0 text-18">
                      {{ icons.mdiHomeOutline }}
                    </v-icon><br>
                    <p class="text-12">Beranda</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize text-10"
                height="60px"
                tile
                @click="goToRekom()"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'rekomendasi'" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiLightningBolt }}
                    </v-icon><br>
                    <p class="text-12 primary--text">Rekomendasi</p>
                  </div>
                  <div v-else>
                    <v-icon class="mb-0 mt-0 text-18">
                    {{ icons.mdiLightningBoltOutline }}
                  </v-icon><br>
                  <p class="text-12">Rekomendasi</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize text-10"
                height="60px"
                tile
                @click="goToPackage()"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'package'" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiBriefcaseAccount  }}
                    </v-icon><br>
                    <p class="text-12  primary--text">Paket Influencer</p>
                  </div>
                  <div v-else>
                    <v-icon class="mb-0 mt-0 text-18">
                      {{ icons.mdiBriefcaseAccountOutline  }}
                    </v-icon><br>
                    <p class="text-12">Paket Influencer</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="3"
              class="text-center pl-0 pr-0"
            >
              <v-card
                color="white"
                elevation="0"
                class="accent--text text-capitalize text-10"
                height="60px"
                tile
                @click="goToCampaign()"
              >
                <v-card-text class="pa-0 pt-2">
                  <div v-if="menu === 'campaign'" >
                    <v-icon class="mb-0 mt-0 text-18 primary--text">
                      {{ icons.mdiBullhorn }}
                    </v-icon><br>
                    <p class="text-12 primary--text">Campaign</p>
                  </div>
                  <div v-else >
                    <v-icon class="mb-0 mt-0 text-18">
                      {{ icons.mdiBullhornOutline }}
                    </v-icon><br>
                    <p class="text-12">Campaign</p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-bottom-navigation>
    </v-app>

    <!-- DESKTOP -->
    <v-app v-else>
      <!-- <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu> -->
      <v-app-bar
        app
        flat
        absolute
        color="primary"
        elevate-on-scroll
        class="fixed"
        style="z-index: 1000;"
      >
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-6">
            <!-- Left Content -->
            <router-link
              to="/"
              class="d-flex align-center text-decoration-none"
            >
              <v-img
                :src="require('@/assets/images/logos/logo-primary-white.png')"
                width="100px"
                alt="logo"
                contain
                eager
                class="app-logo me-3"

              ></v-img>
              <v-slide-x-transition>
                <!-- <h2 class="app-title text--primary">
                  VDC Platform
                </h2> -->
              </v-slide-x-transition>
            </router-link>

            <v-spacer></v-spacer>

            <!-- Right Content -->
            <v-form
              v-if="role === 'admin'"
              ref="form"
              v-model="valid"
              lazy-validation
            >
            <v-row no-gutters class="">
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    solo
                    dense
                    rounded
                    required
                    :rules="[v => !!v || '']"
                    @keyup.enter="validate"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn @click.prevent="validate" aria-label="submit" fab small class="mx-2" color="white">
                    <v-icon>{{ icons.mdiMagnify }}</v-icon>
                  </v-btn>
                </v-col>
            </v-row>
            </v-form>
            <v-btn @click.prevent="goToJoinTalent()" text rounded class="text-capitalize white--text mr-2">
              Join Talent
            </v-btn>
            <v-btn @click.prevent="goToPackage()" text rounded class="text-capitalize white--text mr-2">
              Paket Influencer
            </v-btn>
            <v-btn @click.prevent="goToCampaign()" text rounded class="text-capitalize white--text mr-2">
              Campaign
            </v-btn>
            <app-bar-user-menu :business_name="business_name" :pic_name="pic_name"></app-bar-user-menu>
          </div>
        </div>
      </v-app-bar>
      <v-main>
        <div class="app-content-container boxed-container pa-6">
          <slot></slot>
        </div>
      </v-main>
      <v-footer
        app
        inset
        color="transparent"
        absolute
        height="56"
        class="px-0"
      >
        <div class="boxed-container w-full">
          <v-row class="mb-0 pl-9 pr-9 pt-0">
            <v-card class="primary round" elevation="0">
              <v-row class="pt-6 pb-1 mb-0">
                <v-col cols="12" class="text-center">
                  <center>
                    <v-card color="white" width="100" shaped elevation="2">
                      <v-card-text>
                        <v-img
                          :src="require('@/assets/images/logos/logo-primary.png')"
                          width="70px"
                          alt="logo"
                          contain
                          eager
                          class="app-logo"
                        ></v-img>
                      </v-card-text>
                    </v-card>
                  </center>
                </v-col>
                <v-col cols="12" class="text-center pt-0 white--text ">
                  <p class="title font-weight-bold mb-0">Vice Digital Creative</p>
                  <p class="text-14">Influencer & KOL Management Platform</p>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-around pt-0">
                  <v-btn
                    rounded
                    color="white"
                    elevation="0"
                    class="text-lowercase primary--text"
                    @click="openLink('instagram')"
                  >
                    <i class="fab fa-instagram text-18 mr-2"></i> vicedigitalcreative
                  </v-btn>
                  <v-btn
                    rounded
                    color="white"
                    elevation="0"
                    class="text-lowercase primary--text"
                    @click="openLink('facebook')"
                  >
                    <i class="fab fa-facebook-square text-18 mr-2"></i> vicedigitalcreative
                  </v-btn>
                  <v-btn
                    rounded
                    color="white"
                    elevation="0"
                    class="text-lowercase primary--text"
                    @click="openLink('tiktok')"
                  >
                    <i class="fab fa-tiktok text-18 mr-2"></i> vicedigitalcreative
                  </v-btn>
                  <v-btn
                    rounded
                    color="white"
                    elevation="0"
                    class="text-capitalize primary--text"
                    @click="openLink('linkedin')"
                  >
                    <i class="fab fa-linkedin text-18 mr-2"></i> Vice Digital Creative
                  </v-btn>
                </v-col>
                <v-col cols="12" class="white--text text-center pt-5">
                  <p class="text-14 mb-0">&copy; 2023 All Rights Reserved</p>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <div class="d-flex justify-center mt-1 mb-1">
            <div>
              <v-btn @click="goToAbout()" text elevation="0" rounded color="primary" class="text-capitalize">
                About
              </v-btn>
              <v-btn @click="goToTerm()" text elevation="0" rounded color="primary" class="text-capitalize">
                Term & Conditions
              </v-btn>
              <v-btn @click="goToPrivacy()" text elevation="0" rounded color="primary" class="text-capitalize">
                Privacy Policy
              </v-btn>
              <v-btn @click="goToFaq()" text elevation="0" rounded color="primary" class="text-capitalize">
                FAQ
              </v-btn>
            </div>
            <!-- <span class="d-sm-inline d-none">
              <v-btn @click="goToAbout()" outlined elevation="0" rounded color="primary" class="text-capitalize">
                About VDC
              </v-btn> -->
              <!-- VDC Platform -->
              <!-- &copy; 2023 Vice Digital Creative | All Rights Reserved -->
            <!-- </span> -->
          </div>
        </div>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiHomeOutline, mdiLightningBoltOutline, mdiBullhornOutline, mdiCardAccountDetailsOutline, mdiDotsGrid, mdiClipboardAccountOutline, mdiBriefcaseAccountOutline, mdiHome, mdiBriefcaseAccount, mdiLightningBolt, mdiBullhorn } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'Platform',
  metaInfo: {
    title: 'VDC Platform',
    titleTemplate: '%s | VDC Platform'
  },
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  setup() {
    // const isDrawerOpen = ref(null)

    return {
      isDrawerOpen:false,
      business_name:'',
      pic_name:'',
      role:'',
      page:'',
      pageItem:'',
      search:'',
      isMobileWidth:0,
      menu:'',
      valid:true,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiHomeOutline,
        mdiLightningBoltOutline,
        mdiBullhornOutline,
        mdiCardAccountDetailsOutline,
        mdiDotsGrid,
        mdiClipboardAccountOutline,
        mdiBriefcaseAccountOutline,
        mdiHome,
        mdiBriefcaseAccount,
        mdiLightningBolt,
        mdiBullhorn
      },
    }
  },

  mounted(){
    this.getTitle()
    this.page = this.$route.path
    if(this.$route.name === 'category-all'){
      this.pageItem = 'Kategori'
    } else if(this.$route.name === 'nano-influencer'){
      this.pageItem = 'Nano Influencer'
    } else if(this.$route.name === 'micro-influencer'){
      this.pageItem = 'Micro Influencer'
    } else if(this.$route.name === 'premium-influencer'){
      this.pageItem = 'Premium Influencer'
    } else if(this.$route.name === 'rekomendasi-influencer'){
      this.menu = 'rekomendasi'
      this.pageItem = 'Rekomendasi Influencer'
    } else if(this.$route.name === 'package-all'){
      this.menu = 'package'
      this.pageItem = 'Paket Influencer'
    } else if(this.$route.name === 'campaign'){
      this.menu = 'campaign'
      this.pageItem = 'Campaign'
    } else if(this.$route.name === 'package-detail'){
      this.pageItem = 'Paket Detail'
    } else if(this.$route.name === 'influencer-detail'){
      this.pageItem = 'Detail Influencer'
    } else if(this.$route.name === 'category-detail'){
      this.pageItem = this.$route.query.item
    } else if(this.$route.name === 'faq'){
      this.pageItem = 'Frequently Asked Questions'
    } else {
      this.pageItem = this.$route.name
    }

    if(!localStorage.getItem('token')){
      // this.$router.push({name:'login'});
    } else {
      let token = VueJwtDecode.decode(localStorage.getItem('token'))
      let user = {
        'user_id': token.id,
        'business_name': token.business_name,
        'pic_name': token.pic_name,
        'role': token.role
      }
      localStorage.setItem('user', JSON.stringify(user));
      let getUser = JSON.parse(localStorage.getItem('user'));
      this.business_name = getUser.business_name;
      this.pic_name = getUser.pic_name;
      this.role = token.role
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    validate () {
      let checkValid = this.$refs.form.validate()
      if(checkValid){
        this.submit();
      }
    },
    submit(){
      this.$router.push({name:'search-influencer', query:{keyword:this.search.toString()}});
    },
    getTitle(){
      if(this.$route.fullPath.includes('/category/') || this.page === '/'){
        this.menu = 'home'
      }
    },
    goToJoinTalent(){
      this.$router.push({name:'join-talent'});
    },
    goToPackage(){
      this.$router.push({name:'package-all'});
    },
    goToCampaign(){
      this.$router.push({name:'campaign'});
    },
    goToHome(){
      this.$router.push({name:'home'});
    },
    goToRekom(){
      this.$router.push({name:'rekomendasi-influencer'});
    },
    goToTerm(){
      this.$router.push({name:'term&conditions'});
    },
    goToPrivacy(){
      this.$router.push({name:'privacy-policy'});
    },
    goToFaq(){
      this.$router.push({name:'faq'});
    },
    goToAbout(){
      this.$router.push({name:'about'});
    },
    openLink(val){
      if(val === 'instagram'){
        window.open('https://www.instagram.com/vicedigitalcreative/', '_blank');
      } else if(val === 'linkedin'){
        window.open('https://www.linkedin.com/company/vice-digital-creative', '_blank');
      } else if(val === 'facebook'){
        window.open('https://www.facebook.com/vicedigitalcreative/', '_blank');
      } else if(val === 'tiktok'){
        window.open('https://vt.tiktok.com/ZGJSB4Gnk/', '_blank');
      }
    },
    cart(){
      this.$router.push({name:'campaign-cart'});
    },
    isMobile() {
      let size
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        size = 1
      } else {
        size = 0
      }

      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(size === 1){
        this.isMobileWidth =1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters: {
    title(val){
      let newVal = val.replace("&", " & ");
      if(val === 'category-all'){
        return 'Kategori'
      } else {
        return newVal.replace("-", " ");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}
</style>
