<template>
  <v-navigation-drawer
    v-if="tes"
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu white"
    style="z-index: 1001"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <!-- <v-img
          :src="require('@/assets/images/logos/logo-primary.png')"
          width="50px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img> -->
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            VDC App
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Beranda"
        :to="{ name: 'home' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Rekomendasi"
        :to="{ name: 'rekomendasi-influencer' }"
        :icon="icons.mdiLightningBoltOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Paket Influencer"
        :to="{ name: 'package-all' }"
        :icon="icons.mdiBriefcaseAccountOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Campaign"
        :to="{ name: 'campaign' }"
        :icon="icons.mdiBullhornOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Join Talent"
        :to="{ name: 'join-talent' }"
        :icon="icons.mdiAccountMultiplePlusOutline"
      ></nav-menu-link>
      <nav-menu-section-title></nav-menu-section-title>
      <nav-menu-link
        title="About VDC"
        :to="{ name: 'about' }"
        :icon="icons.mdiAccountHeartOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Term & Conditions"
        :to="{ name: 'term&conditions' }"
        :icon="icons.mdiFileCheckOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Privacy Policy"
        :to="{ name: 'privacy-policy' }"
        :icon="icons.mdiShieldCheckOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="FAQ"
        :to="{ name: 'faq' }"
        :icon="icons.mdiChatQuestionOutline"
      ></nav-menu-link>
      <div v-if="!isLogin">
        <nav-menu-section-title></nav-menu-section-title>
        <nav-menu-link
          title="Login"
          :to="{ name: 'login' }"
          :icon="icons.mdiAccountArrowLeftOutline "
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'register' }"
          :icon="icons.mdiAccountPlusOutline "
        ></nav-menu-link>
      </div>
      <!-- <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link> -->
      <nav-menu-group
        v-if="role === 'admin'"
        title="Master"
        :icon="icons.mdiDatabase"
      >
        <nav-menu-link
          title="Quotation"
          :to="{ name: 'master-quotation' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!-- <nav-menu-link
        title="Quotation"
        :to="{ name: 'quotation' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="Campaign"
        :to="{ name: 'campaign'}"
        :icon="icons.mdiBullhorn"
      ></nav-menu-link>
      <nav-menu-link
        title="Laporan"
        :to="{ name: 'report'}"
        :icon="icons.mdiFileOutline"
      ></nav-menu-link> -->
      
      <!-- <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
      <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
    </v-list>
    <!-- <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiBullhorn,
  mdiDatabase,
  mdiLightningBoltOutline,
  mdiBriefcaseAccountOutline,
  mdiBullhornOutline,
  mdiAccountGroup,
  mdiAccountHeart,
  mdiShieldCheck,
  mdiFileCheckOutline,
  mdiChatQuestionOutline,
  mdiAccountArrowLeftOutline,
  mdiAccountArrowRightOutline,
  mdiAccountPlusOutline,
  mdiAccountMultiplePlusOutline,
  mdiAccountHeartOutline,
  mdiShieldCheckOutline          
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

import VueJwtDecode from 'vue-jwt-decode'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      role:'',
      tes:true,
      isLogin:false,
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiBullhorn,
        mdiDatabase,
        mdiLightningBoltOutline,
        mdiBriefcaseAccountOutline,
        mdiBullhornOutline,
        mdiAccountGroup,
        mdiAccountHeart,
        mdiShieldCheck,
        mdiFileCheckOutline,
        mdiChatQuestionOutline,
        mdiAccountArrowLeftOutline,
        mdiAccountArrowRightOutline,
        mdiAccountPlusOutline,
        mdiAccountMultiplePlusOutline,
        mdiAccountHeartOutline,
        mdiShieldCheckOutline          
      },
    }
  },
  mounted(){
    if(!localStorage.getItem('token')){
      // this.$router.push({name:'login'});
      this.isLogin = false
    } else {
      let token = VueJwtDecode.decode(localStorage.getItem('token'))
      this.role = token.role
      this.isLogin = true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
